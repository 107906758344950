import React, { useState, useEffect } from "react";

import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { generateDebtData, colors } from "../utils";
import MakeAPayment from "./MakeAPayment";

import { calculatePercentages } from "../../../utils/payment";

import { useNavigate } from "react-router";

const PaymentCenter = ({ dues }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [dueNumbers, updateDueNumbers] = useState({});
  const [debtData, setDebtData] = useState({});

  useEffect(() => {
    let percentages = calculatePercentages(dues);
    let graphData = generateDebtData(percentages);
    setDebtData(graphData);
    updateDueNumbers(percentages);
  }, [dues]);

  return (
    <>
      <div className="w-full md:max-w-3xl h-full md:mt-4 bg-slate-100 p-1 rounded-md shadow-md">
        <div className="w-full flex justify-between shadow-sm rounded-md bg-slate-200">
          <div className="px-3">debt summary</div>
        </div>
        <div className="flex w-full md:h-36 h-full flex-wrap md:flex-nowrap relative ">
          <div className="flex w-full md:w-2/3 h-32 relative py-1">
            <div className="relative w-full h-full">
              <div className="absolute w-16 h-8 flex-wrap text-xs text-center top-2/4 left-[calc(50%-2rem)]">
                current progress
              </div>
              <ResponsiveRadialBar
                data={debtData.data}
                startAngle={debtData.startAngle}
                endAngle={debtData.endAngle}
                cornerRadius={debtData.cornerRadius}
                enableCircularGrid={debtData.enableCircularGrid}
                enableRadialGrid={debtData.enableCircularGrid}
                maxValue={debtData.maxValue}
                innerRadius={debtData.innerRadius}
                padding={debtData.padding}
                radialAxisStart={debtData.radialAxisStart}
                circularAxisOuter={debtData.circularAxisOuter}
                margin={{ top: 0, bottom: -100, left: 0, right: 0 }}
                colors={colors}
                colorBy="index"
              />
            </div>
            <div className="w-60 h-full flex items-center justify-center flex-col border-l border-gray-400/30">
              <div className="text-xs h-auto">debt owed</div>
              <div className="text-6xl">{dueNumbers.total}</div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/3 h-full p-3 justify-between items-center">
            <div className="w-full h-[45%] border border-gray-500 shadow-md flex rounded-md">
              <div className="text-sm text-emerald-500">monthly</div>
              <div className="w-full flex justify-center items-center text-4xl text-emerald-700/80">
                {dueNumbers.member}
              </div>
            </div>
            <div className="w-full h-[45%] border border-gray-500 shadow-md flex rounded-md">
              <div className="text-sm text-cyan-600"> other</div>
              <div className="w-full flex justify-center items-center text-4xl text-cyan-800/80">
                {dueNumbers.community}
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full py-1 mt-2 flex justify-center items-center rounded-xl bg-slate-900 hover:bg-blue-500 text-white disabled:bg-slate-500"
          onClick={() => {
            navigate("/payment");
          }}
        >
          make a payment
        </div>
      </div>

      {visible && <MakeAPayment setVisible={setVisible} />}
    </>
  );
};

export default PaymentCenter;
