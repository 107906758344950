import { useEffect, useState } from "react";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { handleUpdateUserHistory } from "../../../utils/history";
import { useAuthUser } from "react-auth-kit";

const PayerCheckoutForm = ({ setSuccess, setFailure, setOption, option }) => {
  const auth = useAuthUser();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment`,
      },
      redirect: "if_required",
    });

    if (error) {
      setFailure(true);
    } else if (paymentIntent) {
      console.log("payment intetn", paymentIntent);
      if (paymentIntent.status === "succeeded") {
        const { username } = auth();
        let body = {};
        switch (option) {
          case 0:
            body["description"] = "you paid a month off your debt";
            break;
          case 1:
            body["description"] = "you paid this year of debt off";
            break;
          case 2:
            body["description"] = "you paid half a year of debt off";
            break;
          case 3:
            body["description"] = "you paid towards your contribution debt";
            break;
          default:
        }

        body["username"] = username;
        body["id"] = paymentIntent.id;
        body["amount"] = paymentIntent.amount.toFixed(2);
        body["type"] = "payment";
        body["option"] = option;

        handleUpdateUserHistory(body).then((res) => {
          if (res) {
            setSuccess(true);
            setOption(-1);
          }
        });
      }
    }

    setLoading(false);
  };

  //   useEffect(() => {
  //     console.log("stripe", stripe);
  //     if (!stripe) return;
  //     if (!clis) return;

  //     stripe.retrievePaymentIntent(clis).then(({ paymentIntent }) => {
  //       switch (paymentIntent.status) {
  //         case "succeeded":
  //           //   alert("payment with through");
  //           break;
  //         default:
  //         //   alert("something went wrong");
  //       }
  //     });
  //   }, [stripe]);

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button
          className="md:w-[calc(100%-6rem)] w-full h-7 flex justify-center items-center rounded-md bg-slate-800 text-sm text-slate-50 mt-3 md:absolute bottom-[3rem] left-[3rem]"
          id="submit"
          disabled={!stripe || !elements || loading}
        >
          {loading ? "loading..." : "pay now"}
        </button>
      </form>
    </div>
  );
};

export default PayerCheckoutForm;
