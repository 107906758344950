const generateDebtData = (data) => {
  return {
    startAngle: -90,
    endAngle: 90,
    cornerRadius: 30,
    innerRadius: 0.65,
    enableCircularGrid: false,
    enableRadialGrid: false,
    maxValue: 100,
    padding: 0.5,
    radialAxisStart: null,
    circularAxisOuter: null,
    data: [
      { id: "mon-debt", data: [{ x: "monthly", y: data.memberPercentage }] },
      {
        id: "group-debt",
        data: [{ x: "contribution", y: data.communityPercentage }],
      },
    ],
  };
};

const colors = ["rgb(22 163 74)", "rgb(8 145 178)"];
const getColor = (radial) => colors[radial.id];

module.exports = {
  generateDebtData,
  colors,
};
