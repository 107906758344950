const Error = () => {
  return (
    <div className="w-screen min-h-screen flex flex-col justify-center items-center">
      <h3 className="text-5xl mb-8">Oops!</h3>
      <div className="text-sm text-slate-500">
        there was an issue with this url. please double check the url
      </div>
      <div className="text-6xl text-slate-400 mt-8">404</div>
    </div>
  );
};

export default Error;
