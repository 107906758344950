import axios from "axios";
import { baseURL } from "./env";
import { getYear, getMonth } from "date-fns";

const instance = axios.create({ baseURL: `${baseURL}/api` });

export const createPaymentIntent = async ({ type, amount }) => {
  try {
    let res = await instance.post("/payment/create-payment-intent", {
      type: type,
      amount: amount,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    return { message: "there was an error generating payment intent" };
  }
};

export const gatherMoneyData = async ({ username }) => {
  try {
    let res = await instance.post("/user/grabByUsername", {
      username: username,
    });
    if (res.status === 200) {
      return {
        message: "successfully grabbed user by id",
        data: res.data.user.dues,
      };
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const calculatePercentages = (dues) => {
  let owed = { member: { paid: 0, owed: 0 }, community: { paid: 0, owed: 0 } };

  for (let i = 0; i < dues.length; i++) {
    let year = dues[i];
    if (year.paid) continue;
    for (let j = 0; j <= 11; j++) {
      if (year.months[j].paid) {
        owed.member.paid = owed.member.paid + year.months[j].memberDue;
        owed.community.paid =
          owed.community.paid + year.months[j].commmunityDue;

        owed.member.owed = owed.member.owed + year.months[j].memberDue;
        owed.community.owed =
          owed.community.owed + year.months[j].commmunityDue;
      } else {
        owed.member.owed = owed.member.owed + year.months[j].memberDue;
        owed.community.owed =
          owed.community.owed + year.months[j].commmunityDue;
      }
    }
  }

  let memberPercentage = 0;
  let communityPercentage = 0;

  if (owed.member.owed === 0) {
    memberPercentage = 100;
  } else {
    memberPercentage = Math.ceil(
      Math.ceil((owed.member.paid / owed.member.owed) * 100)
    );
  }

  if (owed.community.owed === 0) {
    communityPercentage = 100;
  } else {
    communityPercentage = Math.ceil(
      Math.ceil((owed.community.paid / owed.community.owed) * 100)
    );
  }

  return {
    total:
      owed.member.owed -
      owed.member.paid +
      (owed.community.owed - owed.community.paid),
    member: owed.member.owed - owed.member.paid,
    community: owed.community.owed - owed.community.paid,
    memberPercentage: memberPercentage,
    communityPercentage: communityPercentage,
  };
};

export const calculatePaymentOptions = (dues) => {
  const owed = {
    month: { amount: 0, paid: false },
    year: { amount: 0, paid: false },
    half: { amount: 0, paid: false },
    total: { amount: 0, paid: false },
  };
  let currentYear = getYear(new Date());
  let month = getMonth(new Date()) + 1;
  let paidUpToDate = true;

  for (let i = 0; i < dues.length; i++) {
    let year = dues[i];
    if (year.paid) {
      owed.year.paid = true;
    }
    for (let j = 0; j <= 11; j++) {
      if (year.number === currentYear) {
        if (year.paid) {
          owed.year.paid = true;
        }

        owed.year.amount = owed.year.amount + year.months[j].amount;
      }

      if (owed.year.paid) continue;

      if (year.months[j].number === month && !year.paid) {
        if (year.months[j].paid) {
          owed.month.paid = true;
        }
        owed.month.amount = year.months[j].amount;
      }

      owed.total.amount = owed.total.amount + year.months[j].amount;
    }
  }

  owed.half.amount = Math.ceil(owed.total.amount / 2);

  return {
    month: { amount: 10, paid: false },
    half: { amount: 60, paid: false },
    year: { amount: 120, paid: false },
    total: { amount: 0, paid: false },
  };

  return { ...owed, paidUpToDate: paidUpToDate };
};

export const calculateFees = (amount) => {
  return (amount + 30) / (1 - 0.029);
};
