import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const MakeAPayment = ({ setVisible }) => {
  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          key="payer"
          className="absolute z-50 w-screen h-screen md:w-10/12 md:h-5/6 flex flex-col justify-center items-center text-orange-600 backdrop-blur-md rounded-md"
        >
          <div>payment modal</div>
          <div
            onClick={() => {
              setVisible(false);
            }}
          >
            close
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default MakeAPayment;
