import { useEffect, useState } from "react";
import {
  grabAllUsers,
  months,
  handleUserDataUpdate,
} from "../../../utils/admin/editor";
import { getYear } from "date-fns";
import { FaTrashAlt } from "react-icons/fa";
import { formatDistance } from "date-fns";

const MemberEditor = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentData, setCurrentData] = useState([]);

  const gatherUsers = async () => {
    let res = await grabAllUsers();

    if (res.users) {
      setUsers(res.users);
    }
  };

  const addYear = () => {
    const year = {
      year: getYear(new Date()),
      paid: false,
      datePaid: "",
      months: new Array(12).fill(0).map((month, i) => ({
        number: i + 1,
        paid: false,
        datePaid: "",
        amount: 10,
        memberDue: 10,
        communityDue: 0,
      })),
    };

    let curr = [...currentData];
    curr.push(year);

    setCurrentData([...curr]);
  };

  const removeYear = (i) => {
    let curr = [...currentData];
    curr.splice(i, 1);

    setCurrentData([...curr]);
  };

  const handleYearChange = (index, type, change, monthIndex = -1) => {
    let curr = [...currentData];
    let targetYear = curr[index];

    if (type === "year") {
      targetYear.year = change;
    } else if (type === "month" && monthIndex > -1) {
      targetYear.months[monthIndex].amount = change;
      targetYear.months[monthIndex].memberDue = change;
    }

    setCurrentData([...curr]);
  };

  const handleSave = () => {
    let result = handleUserDataUpdate(currentData, currentUser._id);
    if (result) setCurrentUser(undefined);
    gatherUsers();
  };

  useEffect(() => {
    gatherUsers();
  }, []);

  useEffect(() => {
    if (!currentUser) {
      setCurrentData([]);

      return;
    }
    if (currentUser.dues) {
      setCurrentData([...currentUser.dues]);
    }
  }, [currentUser]);

  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="w-full md:w-5/6">
        <table className="w-full border-2 text-sm rounded-t-lg">
          <thead className="text-lg bg-stone-500 rounded-t-lg">
            <tr>
              <td>name</td>
              <td>gropus</td>
              <td>last edited</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {users.map((user, i) => (
              <TableRow user={user} key={i} setUser={setCurrentUser} />
            ))}
          </tbody>
        </table>
      </div>
      {currentUser && (
        <UserEditor
          user={currentUser}
          currentData={currentData}
          addYear={addYear}
          removeYear={removeYear}
          handleChange={handleYearChange}
        />
      )}

      {currentUser && (
        <div className="w-full md:w-5/6 flex justify-start">
          <button
            className="px-2 py-1 rounded-md bg-orange-500 mt-3"
            onClick={() => {
              handleSave();
            }}
          >
            save
          </button>
        </div>
      )}
    </div>
  );
};

const TableRow = ({ user, setUser }) => {
  return (
    <>
      <tr className="border-b border-b-slate-500 last:border-b-0">
        <td>{`${user.firstname} ${user.lastname}`}</td>
        <td>{user.groups ? user.groups.join(", ") : "none"}</td>
        <td>
          {user.lastEdited
            ? formatDistance(new Date(user.lastEdited), new Date(), {
                addSuffix: true,
              })
            : "never"}
        </td>
        <td>
          <button
            className="bg-purple-800/70 px-2 py-1 my-2 rounded-md hover:text-white hover:bg-purple-900"
            onClick={() => setUser(user)}
          >
            edit
          </button>
        </td>
      </tr>
    </>
  );
};

const UserEditor = ({
  user,
  currentData,
  addYear,
  removeYear,
  handleChange,
}) => {
  return (
    <div className="w-full md:w-5/6 md:mt-6 ">
      <div className="w-full flex justify-center text-lg">
        <div>currently editing: </div>
        <div className="pl-4 font-bold text-xl">{` ${user.firstname} ${user.lastname}`}</div>
      </div>
      <button
        onClick={() => addYear()}
        className="px-2 py-1 bg-emerald-500 rounded-lg"
      >
        add year
      </button>
      {currentData.map((year, i) => (
        <YearlyData
          year={year}
          removeYear={removeYear}
          index={i}
          onYearChange={handleChange}
        />
      ))}
    </div>
  );
};

const YearlyData = ({ year, index, removeYear, onYearChange }) => {
  return (
    <div className="w-full">
      <div className="flex items-center">
        <div className="font-bold text-2xl">
          <input
            value={year.year}
            type="number"
            className="bg-transparent underline w-20"
            onChange={(e) => {
              onYearChange(index, "year", e.target.value);
            }}
          />
        </div>
        <button
          className="text-red-500 text-sm ml-2"
          onClick={() => removeYear(index)}
        >
          {<FaTrashAlt />}
        </button>
      </div>
      <div className=" grid grid-cols-12 w-full">
        {year.months.map((month, i) => (
          <div className="flex flex-col justify-start items-center text-xs ">
            <div>{months[month.number]}</div>
            <input
              type="number"
              value={month.amount}
              className="font-bold bg-transparent underline w-8 pl-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              onChange={(e) => {
                onYearChange(index, "month", e.target.value, i);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberEditor;
