import axios from "axios";
import { baseURL } from "../env";
import { formatDistance, isPast } from "date-fns";

import { FaRegTimesCircle } from "react-icons/fa";
import { CgTime } from "react-icons/cg";
import { TbCircleCheck } from "react-icons/tb";

const instance = axios.create({ baseURL: `${baseURL}/api` });

export const handleAddInvite = async (email, isAdmin) => {
  try {
    let res = await instance.post("/email/invite", {
      email: email,
      isAdmin: isAdmin,
    });
    if (res.status === 200) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const getInviteStatuses = async (setStatuses, setCounts) => {
  try {
    let res = await instance.get("email/all_codes");
    if (res.status === 200) {
      setStatuses([...res.data.codes]);
      let counts = getStatusCounts(res.data.codes);
      setCounts([...counts]);
    }
  } catch (e) {
    return setStatuses([]);
  }
};

export const handleInviteExpiry = (date) => {
  const result = formatDistance(new Date(date), new Date());
  const past = isPast(new Date(date));
  return `${result} ${past ? "ago" : ""}`;
};

export const handleInviteCurrent = (date, used) => {
  if (used) {
    return (
      <div className="bg-green-500 text-green-600 bg-opacity-50 rounded-md flex items-center p-1 w-[7.3rem]">
        <div className="mr-1">
          <TbCircleCheck />
        </div>
        <div>completed</div>
      </div>
    );
  }

  if (!isPast(new Date(date))) {
    return (
      <div className="bg-sky-500 text-sky-600 bg-opacity-50 rounded-md flex items-center p-1 w-[7.3rem]">
        <div className="mr-1">
          <CgTime />
        </div>
        <div>in progress</div>
      </div>
    );
  } else {
    return (
      <div className="bg-pink-500 text-pink-600 bg-opacity-50 rounded-md flex items-center p-1 w-[7.3rem]">
        <div className="mr-1">
          <FaRegTimesCircle />
        </div>
        <div>expired</div>
      </div>
    );
  }
};

export const getStatusCounts = (codes) => {
  let expired = 0;
  let completed = 0;
  let pending = 0;

  codes.forEach((el) => {
    if (el.used) {
      completed++;
    } else if (!isPast(new Date(el.expiry))) {
      pending++;
    } else {
      expired++;
    }
  });

  return [completed, expired, pending];
};
