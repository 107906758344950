import { Outlet } from "react-router";
import Nav from "../components/nav";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

const Frame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/user");
    }
  }, [location]);

  return (
    <div className="min-h-screen w-screen">
      <Nav />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Frame;
