import axios from "axios";
import { baseURL } from "../env";

const instance = axios.create({
  baseURL: `${baseURL}/api`,
});

export const months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const grabAllUsers = async () => {
  try {
    let res = await instance.get("/user/all-users");
    if (res.status === 200) {
      return { message: "successfully grabbed users", users: res.data.users };
    } else {
      return { message: "there was an issue gathering users" };
    }
  } catch (err) {
    return { message: "there as an error gathering users", err: err };
  }
};

export const handleUserDataUpdate = async (userData, userId) => {
  const data = { ...userData };
  try {
    let res = await instance.post("/user/update-user", {
      uId: userId,
      data: data,
    });

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log("there was an error updating the user data", e);
    return false;
  }
};
