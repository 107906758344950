import axios from "axios";
import { baseURL } from "./env";

export const loginHelper = async ({ username, password }) => {
  let result = false;
  const instance = axios.create({ baseURL: `${baseURL}/api` });

  const values = { username: username, password: password };
  try {
    let res = await instance.post("/user/login", values);
    if (res.status === 200) {
      console.log("login res", res);
      return res;
    }
  } catch (e) {
    return result;
  }

  return result;
};
