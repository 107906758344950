import "./App.css";
import BasicTextField from "./components/input/basicTextfield";
import { Input } from "@material-tailwind/react";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AdminOutline from "./pages/admin/AdminOutline";
import SignupCompleted from "./pages/status/SignupCompleted";

function App() {
  return (
    <div className="font-spaceMono">
      <Login />
      <Signup />
      <AdminOutline />
      <SignupCompleted />
      {/* <Input label="test" /> */}
    </div>
  );
}

export default App;
