import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PaymentCenter from "./components/PaymentCenter";
import HistoryCenter from "./components/History";

import { useAuthUser } from "react-auth-kit";
import { gatherMoneyData } from "../../utils/payment";
import { getHistory } from "../../utils/history";

const UserOutline = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [dues, setDues] = useState([]);
  const [history, setHistory] = useState([]);

  const getDues = async () => {
    const { username } = auth();
    let res = await gatherMoneyData({ username: username });
    if (res) {
      setDues(res.data);
    }
  };

  const getHistoryHelper = async () => {
    const { username } = auth();
    let res = await getHistory(username);

    if (res) {
      setHistory(res);
    }
  };

  useEffect(() => {
    getDues();
  }, []);

  useEffect(() => {
    getHistoryHelper();
  }, []);

  useEffect(() => {
    const { history } = auth();
    setHistory(history);
  }, []);

  return (
    <div className="w-screen min-h-fit flex flex-col justify-center items-center">
      <PaymentCenter dues={dues} />
      <HistoryCenter history={history} />
    </div>
  );
};

export default UserOutline;
