import { useState } from "react";
import { loginHelper } from "../utils/login";
import { useNavigate } from "react-router";
import { useSignIn } from "react-auth-kit";

const Login = () => {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [error, setError] = useState(false);
  const [login, setLogin] = useState({ username: "", password: "" });

  const handleLoginChange = (e) => {
    if (error) setError(!error);
    const { name, value } = e.target;
    setLogin((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleLoginSubmit = async () => {
    let res = await loginHelper(login);
    if (!res) {
      setError(true);
    } else {
      setError(false);
      signIn({
        token: res.data.token,
        expiresIn: 720,
        tokenType: "Bearer",
        authState: {
          firstname: res.data.user.firstname,
          lastname: res.data.user.lastname,
          username: res.data.user.username,
          email: res.data.user.email,
          isAdmin: res.data.user.groups.includes("admin"),
          userId: res.data.user.id,
          history: res.data.user.history ?? [],
        },
      });
      navigate("/user");
    }
  };

  const errorStyles = error ? "text-pink-600" : "";
  const borderErrorStyles = error
    ? "border-pink-600 focus:border-pink-800"
    : "";

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="p-5 rounded-2xl border shadow-xl min-w-max h-60 flex flex-col justify-around bg-slate-50">
        <h3 className="text-xl underline">login</h3>
        <p className="text-[0.6rem] text-opacity-60 text-slate-500 mb-1">
          welcome back! hope you are having a great day
        </p>
        <div className={`w-64 ${errorStyles}`}>
          <label className="text-xs">username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={login.username}
            onChange={handleLoginChange}
            className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:border-zinc-950 focus:shadow-md ${borderErrorStyles}`}
          />
        </div>
        <div className={`w-64 ${errorStyles}`}>
          <label className="text-xs">password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={login.password}
            onChange={handleLoginChange}
            className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:border-zinc-950 focus:shadow-md ${borderErrorStyles}`}
          />
        </div>
        <button
          className="w-full mt-3 py-1 rounded-lg bg-blue-800 text-white hover:bg-blue-500"
          onClick={() => {
            handleLoginSubmit();
          }}
        >
          login
        </button>
        {/* <div className="w-full text-xs flex justify-end">
          <button
            className="text-blue-500"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            forgot password
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
