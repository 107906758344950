import axios from "axios";
import { baseURL } from "./env";

const instance = axios.create({ baseURL: `${baseURL}/api` });

export const handleSignup = async (
  values,
  confirmWrong,
  emailWrong,
  phoneWrong,
  unameWrong,
  id
) => {
  const {
    firstname,
    lastname,
    email,
    username,
    number,
    confirm,
    password,
    groups,
  } = values;

  if (confirm !== password) {
    confirmWrong(true);
    return;
  }

  const send_values = {
    firstname: firstname,
    lastname: lastname,
    email: email,
    number: number,
    username: username,
    password: password,
    groups: groups,
  };

  try {
    const res = await instance.post("/user/register", send_values);
    if (res.status === 200) {
      updateCodeLog(id, `${firstname} ${lastname}`);
      return true;
    }
  } catch (e) {
    const { reason } = e.response.data;
    if (reason === "username") {
      unameWrong(true);
      return false;
    } else if (reason === "email") {
      emailWrong(true);
      return false;
    } else if (reason === "number") {
      phoneWrong(true);
      return false;
    }

    return false;
  }
};

const updateCodeLog = async (id, name) => {
  try {
    const res = await instance.put(`/email/update/${id}/${name}`);
  } catch (e) {
    return false;
  }
};

export const validateCode = async (code) => {
  try {
    let res = await instance.get(`/email/validate/${code}`);
    return res.data.code;
  } catch (err) {
    return null;
  }
};
