import { useState, useEffect } from "react";
import { handleSignup, validateCode } from "../utils/signup";
import { useParams, useNavigate } from "react-router";
import { Buffer } from "buffer";

const Signup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [signup, setSignup] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    email: "",
    confirm: "",
    number: "",
  });

  const [confirmError, setConfirmError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [used, setUsed] = useState(false);

  const handleSignupChanges = (e) => {
    const { value, name } = e.target;

    if (confirmError && (name === "password" || name === "confirm")) {
      setConfirmError(false);
    }
    if (emailError && name === "email") setEmailError(false);
    if (phoneError && name === "phone") setPhoneError(false);
    if (usernameError && name === "username") setUsernameError(false);

    setSignup((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handlePasswordOnBlur = (e) => {
    const { value } = e.target;
    if (value !== signup.password) {
      setConfirmError(true);
    }
  };

  const handleEmailOnBlur = (e) => {
    const { value } = e.target;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return;
    } else {
      setEmailError(true);
    }
  };

  const submitClick = async () => {
    let res = await handleSignup(
      { ...signup, groups: groups },
      setConfirmError,
      setEmailError,
      setPhoneError,
      setUsernameError,
      id
    );

    if (res) {
      if (groups.includes("admin")) {
        navigate("/admin");
      } else {
        navigate("/creation-success");
      }
    }
  };

  const textConfirmError = confirmError ? "text-pink-600" : "";
  const borderConfirmError = confirmError
    ? "border-pink-500 focus:border-2"
    : "";

  const textEmailError = emailError ? "text-pink-600" : "";
  const borderEmailError = emailError ? "border-pink-500 focus:border-2" : "";

  const textUsernameError = usernameError ? "text-pink-600" : "";
  const borderUsernameError = usernameError
    ? "border-pink-500 focus:border-2"
    : "";

  const textPhoneError = phoneError ? "text-pink-600" : "";
  const borderPhoneError = phoneError ? "border-pink-500 focus:border-2" : "";

  useEffect(() => {
    const checkValid = async () => {
      let g = id.split("+++");
      if (g.length !== 2) {
        setValid(false);
        setLoading(false);
      }

      let validate = await validateCode(id);
      if (validate && new Date(validate.expiry).getTime() > Date.now()) {
        setValid(true);
        if (validate.used) {
          setUsed(true);
        } else {
          setUsed(false);
          setSignup((old) => ({ ...old, email: validate.email }));
          let perms = Buffer.from(g[1], "base64").toString("ascii").split(",");
          setGroups([...perms]);
        }
      } else {
        setValid(false);
      }

      setLoading(false);
    };

    checkValid();
  }, [id]);

  if (used || !valid) {
    return <BadCode />;
  }

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="p-5 rounded-2xl border shadow-xl min-w-max h-[30rem] flex flex-col justify-around">
        <h3 className="text-xl underline">signup</h3>
        <p className="text-[0.6rem] text-opacity-60 text-slate-500 mb-1">
          hello! please enter your details to create an account
        </p>

        <div className="w-[21rem] flex justify-between">
          <div className="w-1/2">
            <div className="w-11/12">
              <label className="text-xs">firstname</label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={signup.firstname}
                onChange={handleSignupChanges}
                className="w-full border rounded-lg px-2 py-1 text-sm focus:outline-none focus:border-zinc-950 focus:shadow-md"
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="w-11/12">
              <label className="text-xs">lastname</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={signup.lastname}
                onChange={handleSignupChanges}
                className="w-full border rounded-lg px-2 py-1 text-sm focus:outline-none focus:border-zinc-950 focus:shadow-md"
              />
            </div>
          </div>
        </div>

        <div className="w-64 block">
          <div>
            <label className={`text-xs ${textEmailError}`}>email</label>
          </div>
          <div>
            <input
              type="text"
              id="email"
              name="email"
              value={signup.email}
              onChange={handleSignupChanges}
              onBlur={handleEmailOnBlur}
              disabled
              className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:shadow-md ${borderEmailError} disabled:text-slate-400`}
            />
          </div>
        </div>

        <div className="w-64">
          <label className={`text-xs ${textUsernameError}`}>username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={signup.username}
            onChange={handleSignupChanges}
            className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:shadow-md ${borderUsernameError}`}
          />
        </div>

        <div className="w-64 block">
          <div>
            <label className={`text-xs ${textPhoneError}`}>phone</label>
          </div>
          <div>
            <input
              type="tel"
              id="number"
              name="number"
              value={signup.number}
              onChange={handleSignupChanges}
              maxLength="10"
              className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:shadow-md ${borderPhoneError}`}
            />
          </div>
        </div>

        <div className="w-64">
          <label className={`text-xs ${textConfirmError}`}>password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={signup.password}
            onChange={handleSignupChanges}
            className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:shadow-md ${borderConfirmError}`}
          />
        </div>

        <div className="w-64">
          <label className={`text-xs ${textConfirmError}`}>
            confirm password
          </label>
          <input
            type="password"
            id="confirm"
            name="confirm"
            value={signup.confirm}
            onChange={handleSignupChanges}
            onBlur={handlePasswordOnBlur}
            className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:shadow-md ${borderConfirmError}`}
          />
        </div>

        <button
          className="w-64 py-1 mt-2 rounded-xl bg-blue-800 hover:bg-blue-500 text-white disabled:bg-slate-500"
          disabled={
            !signup.firstname ||
            !signup.lastname ||
            !signup.email ||
            !signup.number ||
            !signup.password ||
            !signup.confirm ||
            confirmError ||
            emailError ||
            phoneError ||
            usernameError
          }
          onClick={() => submitClick()}
          //   disabled
        >
          submit
        </button>
      </div>
    </div>
  );
};

export default Signup;

const BadCode = () => {
  return (
    <div className="w-screen min-h-screen flex flex-col items-center justify-center">
      <div className="text-4xl mb-5">Error using this link</div>
      <div className="text-sm text-slate-400">
        there was an error using this code. Please try again or contact the
        person who sent you this link.
      </div>
    </div>
  );
};
