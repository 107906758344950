import React, { useState } from "react";
import HistoryItem from "./HistoryItem";
import { HISTORY_TYPES } from "../utils";
import { formatDistance } from "date-fns";

const HistoryCenter = ({ history }) => {
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (type) => {
    setFilter(type);
  };

  return (
    <div className="w-full md:max-w-3xl h-[45vh] mt-2 rounded-lg bg-slate-100 p-1">
      <div className="w-full flex justify-between bg-slate-200 rounded-md">
        <div className="px-3">recent activity</div>
        <nav
          className="justify-around items-center w-2/6 bg-slate-400/80 text-xs rounded-md p-1 md:flex hidden"
          role="tablist"
        >
          <button
            type="button"
            role="tab"
            aria-selected="true"
            className={`h-full w-full flex justify-center items-center rounded-md p-1  ${
              filter === "all" ? "bg-slate-200 font-bold underline" : ""
            }`}
            onClick={() => {
              handleFilterChange("all");
            }}
          >
            all
          </button>
          <button
            disabled
            type="button"
            role="tab"
            className={`h-full w-full flex justify-center items-center rounded-md p-1  ${
              filter === "payments" ? "bg-slate-200 font-bold underline" : ""
            }`}
            onClick={() => {
              handleFilterChange("payments");
            }}
          >
            payments
          </button>
          <button
            disabled
            type="button"
            role="tab"
            className={`h-full w-full flex justify-center items-center rounded-md p-1 ${
              filter === "messages" ? "bg-slate-200 font-bold underline" : ""
            }`}
            onClick={() => {
              handleFilterChange("messages");
            }}
          >
            messages
          </button>
        </nav>
      </div>
      <div className="w-full h-[calc(100%-2rem)] overflow-y-auto relative">
        <table className="table-auto w-full max-h-[90%] overflow-y-auto mt-2 text-sm border ">
          <thead>
            <tr>
              <th>type</th>
              <th>amount</th>
              <th>description</th>
              <th className="text-right pr-3">date</th>
            </tr>
          </thead>
          <tbody className="text-xs relative">
            {history && history.length && history.length > 0 ? (
              history.map((elem, i) => (
                <HistoryItem
                  type={elem.type}
                  amount={Math.ceil(elem.amount / 100).toFixed(2)}
                  message={elem.description}
                  time={formatDistance(new Date(elem.date), new Date(), {
                    addSuffix: true,
                  })}
                />
              ))
            ) : (
              <div className="w-full md:h-60 h-32 flex justify-center items-center absolute">
                no activity to show yet
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoryCenter;
