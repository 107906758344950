import axios from "axios";
import { baseURL } from "./env";

const instance = axios.create({ baseURL: `${baseURL}/api` });

export const handleUpdateUserHistory = async ({
  username,
  description,
  link = "",
  id = "",
  type,
  amount,
  option,
}) => {
  try {
    let res = await instance.post("/history/add-point", {
      username,
      description,
      type,
      id,
      link,
      amount,
      option,
    });

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log("there was an error updating history", e);
  }
};

export const getHistory = async (username) => {
  try {
    let res = await instance.post("/history/get-history", {
      username: username,
    });
    if (res.status === 200) {
      return res.data.history;
    } else {
      return false;
    }
  } catch (e) {
    console.log("there was an error grabbing history", e);
    return false;
  }
};
