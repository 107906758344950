const { NODE_ENV, REACT_APP_STRIPE_PK } = process.env;

const prod = NODE_ENV === "production";

const baseURL = prod
  ? "https://gcav-club-backend.onrender.com"
  : "http://localhost:7070";

const stripe_pk = prod
  ? `${REACT_APP_STRIPE_PK}`
  : "pk_test_51HGoePB7gvq4RyVMtT8Ojm00qwevvUviWciD4xgaDd7xVxDX0cPsEUXPaKMJvL1IStz3BVmpzkqSsBnRIryTmwvk00SWZE4kOj";

module.exports = { baseURL, stripe_pk };
