import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PayerCheckoutForm from "./PayerCheckoutForm";
import { MdOutlinePriceCheck } from "react-icons/md";
import { MdOutlineError } from "react-icons/md";
import { createPaymentIntent } from "../../../utils/payment";
import { useNavigate } from "react-router";
import {
  gatherMoneyData,
  calculatePaymentOptions,
  calculateFees,
} from "../../../utils/payment";
import { useAuthUser } from "react-auth-kit";

import { stripe_pk } from "../../../utils/env";

import { useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(stripe_pk);

const Payer = () => {
  const [queryParameters] = useSearchParams();

  const auth = useAuthUser();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [option, setOption] = useState(-1);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [dues, setDues] = useState([]);
  const [otherValue, setOtherValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "10px",
      colorBackground: "#F6F8FA",
      accessibleColorOnColorPrimary: "#262626",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "8px",
        border: "1px solid #000",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "1px solid #000",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "500",
      },
    },
  };

  const options = {
    appearance,
    clientSecret: clientSecret,
  };

  const handleOptionSelect = (index, enabled) => {
    if (!enabled) return;
    setOption(index);
  };

  const getDues = async () => {
    const { username } = auth();
    let res = await gatherMoneyData({ username: username });
    if (res) {
      setDues(res.data);
    }
  };

  useEffect(() => {
    getDues();
  }, []);

  useEffect(() => {
    const isSucess = queryParameters.get("redirect_status") === "succeeded";
    setSuccess(isSucess);
  }, [queryParameters]);

  useEffect(() => {
    if (!dues) return;
    let payOptions = calculatePaymentOptions(dues);

    const data = [
      {
        type: "month",
        amount: payOptions.month.amount * 100,
        description: "This current month's worth of dues",
        enabled: !payOptions.month.paid && payOptions.month.amount > 0,
        variable: false,
      },

      {
        type: "this year",
        amount: payOptions.year.amount * 100,
        description: "Pay the total member dues for the year",
        enabled:
          !payOptions.year.paid &&
          !payOptions.paidUpToDate &&
          payOptions.year.amount > 0,
        variable: false,
      },
      {
        type: "half year",
        amount: payOptions.half.amount * 100,
        description: "Half of the yearly dues for the year",
        enabled:
          !payOptions.half.paid &&
          !payOptions.paidUpToDate &&
          payOptions.half.amount > 0,
        variable: false,
      },
      {
        type: "other",
        amount: 0,
        description: "Pay contributions and any other dues",
        enabled: true,
        variable: true,
      },
    ];

    setPaymentOptions([...data]);
  }, [dues]);

  useEffect(() => {
    if (option === -1) return;

    if (option === 3) {
      if (otherValue > 0) {
        createPaymentIntent({
          type: paymentOptions[option].type,
          amount: Math.ceil(calculateFees(otherValue * 100)),
        }).then((res) => setClientSecret(res.clientSecret));
      }
    }
    createPaymentIntent({
      type: paymentOptions[option].type,
      amount: Math.ceil(calculateFees(paymentOptions[option].amount)),
    }).then((res) => setClientSecret(res.clientSecret));
  }, [option, otherValue]);

  return (
    <div className="w-screen md:h-[calc(100vh-68px)] h-full flex justify-center items-center md:px-0 px-0">
      <div className="w-full md:min-w-[28rem] md:w-2/6 md:min-h-4/6 md:h-fit h-full border border-gray-700/30 rounded-md shadow-lg overflow-hidden p-4 md:p-12 bg-slate-50 relative">
        {success && (
          <SuccessfulPayment navigate={navigate} setSuccess={setSuccess} />
        )}
        {failure && (
          <ErrorPayment navigate={navigate} setFailure={setFailure} />
        )}
        <div className="w-full h-auto py-4 md:mt-3 mt-0 md:mb-4 mb-2 flex items-center justify-center text-lg">
          <div className="w-5/6 md:w-1/3 text-center font-bold">
            Dues and contribution payments
          </div>
        </div>
        <div className="w-full h-auto grid grid-cols-2 gap-2">
          {paymentOptions.map((elem, i) => (
            <Option
              type={elem.type}
              amount={elem.amount}
              description={elem.description}
              key={i}
              index={i}
              option={option}
              setOption={setOption}
              enabled={elem.enabled}
              handleClick={handleOptionSelect}
              variable={elem.variable}
              otherValue={otherValue}
              setOtherValue={setOtherValue}
            />
          ))}
        </div>
        <div className="mt-10">
          <div className="text-md font-bold flex justify-between">
            <div>total billed</div>
            <div>
              {`${
                option !== -1
                  ? option === 3
                    ? `$${(calculateFees(otherValue * 100) / 100).toFixed(2)}`
                    : `$${(
                        calculateFees(paymentOptions[option].amount) / 100
                      ).toFixed(2)}`
                  : "$$$"
              } `}
            </div>
          </div>
          <div className="text-xs">
            enter your credit card details to complete the transaction
          </div>
        </div>
        <div className="mt-5 mb-11">
          {clientSecret && option !== -1 && (
            <Elements
              options={options}
              stripe={stripePromise}
              key={clientSecret}
            >
              <PayerCheckoutForm
                setSuccess={setSuccess}
                setFailure={setFailure}
                setOption={setOption}
                option={option}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
};

const Option = ({
  type,
  amount,
  description,
  option,
  index,
  handleClick,
  enabled,
  variable = false,
  otherValue,
  setOtherValue,
}) => {
  const handleOtherValueChange = (e) => {
    setOtherValue(e.target.value);
  };

  return (
    <div
      className={`w-full h-full rounded-md p-3 text-sm transition-all ease-in-out duration-75  disabled:bg-slate-500 disabled:text-orange-500 ${
        !enabled
          ? "bg-slate-600 text-slate-400 hover:cursor-not-allowed"
          : option === index
          ? "border-2 border-cyan-600 shadow-lg bg-cyan-600/60 hover:cursor-pointer"
          : "border border-slate-400 bg-transparent hover:cursor-pointer"
      }`}
      disabled={!enabled}
      onClick={() => handleClick(index, enabled)}
    >
      <div className="flex justify-between relative">
        <div className="">
          <input
            type="radio"
            value={amount}
            name={`${type}`}
            id={`${type}`}
            checked={option === index}
            disabled={!enabled}
            readOnly
          />
        </div>
        <div className="w-full ml-2">
          <div className="flex justify-between">
            <label htmlFor={`${type}`}>{type}</label>
            {variable && (
              <>
                <div>$</div>
                <input
                  type="number"
                  value={otherValue}
                  className="font-bold bg-transparent underline w-16 pl-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  id={`$${type}`}
                  onChange={handleOtherValueChange}
                />
              </>
            )}
            {!variable && (
              <div className="font-bold">{`$${Math.ceil(amount / 100).toFixed(
                2
              )}`}</div>
            )}
          </div>
          <div className="text-[0.6rem] ">{description}</div>
        </div>
      </div>
    </div>
  );
};

const SuccessfulPayment = ({ navigate, setSuccess }) => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center absolute z-50 top-0 left-0 bg-emerald-300">
      <div className="text-5xl mb-3">
        <MdOutlinePriceCheck />
      </div>
      <div className="text-lg font-bold">Payment was successful</div>
      <div className="flex justify-around items-center w-4/6 h-auto  mt-4 text-sm">
        <button
          className="bg-slate-800 text-slate-100 rounded-lg p-1 text-sm"
          onClick={() => setSuccess(false)}
        >
          make another payment
        </button>
        <button
          className="bg-slate-800 text-slate-100 rounded-lg p-1 text-sm ml-3"
          onClick={() => navigate("/user")}
        >
          go to homepage
        </button>
        {/* <button
          onClick={() => {
            setSuccess(false);
          }}
        >
          close
        </button> */}
      </div>
    </div>
  );
};

const ErrorPayment = ({ navigate, setFailure }) => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center absolute z-50 top-0 left-0 bg-pink-300">
      <div className="text-5xl mb-3">
        <MdOutlineError />
      </div>
      <div className="text-lg font-bold">
        Payment was unsuccessful. Please try again, or try a different payment
        card/method
      </div>
      <div className="flex justify-around items-center w-4/6 h-auto  mt-4 text-sm">
        <button
          className="bg-slate-800 text-slate-100 rounded-lg p-1 text-sm"
          onClick={() => navigate("/user")}
        >
          go to homepage
        </button>
        <button
          className="bg-slate-800 text-slate-100 rounded-lg p-1 text-sm ml-3"
          onClick={() => setFailure(false)}
        >
          close
        </button>
        {/* <button
          onClick={() => {
            setSuccess(false);
          }}
        >
          close
        </button> */}
      </div>
    </div>
  );
};
export default Payer;
