import { useEffect, useState } from "react";

import { useAuthUser } from "react-auth-kit";

import { useNavigate } from "react-router";

import MemberAdder from "./components/MemberAdder";
import MemberEditor from "./components/MemberEditor";

const tabs = [
  { index: 0, name: "adder" },
  { index: 1, name: "editor" },
];

const AdminOutline = () => {
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [currentAdminTab, setAdminTab] = useState(tabs[0].index);

  useEffect(() => {
    const { admin } = auth();
    if (admin) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="md:h-[calc(100vh-75px)] h-full w-screen flex justify-center md:items-center mt-14 md:mt-0 items-start px-5 md:px-0 relative">
        <div className="w-screen  flex justify-center absolute -top-12 md:top-2 left-0">
          {tabs.map((elem, i) => (
            <button
              className={`border border-slate-600 first:rounded-s-xl last:rounded-e-xl px-2 py-1 text-sm ${
                currentAdminTab === i
                  ? "bg-slate-800 text-slate-50"
                  : "hover:bg-slate-300"
              }`}
              onClick={() => {
                setAdminTab(elem.index);
              }}
            >
              {elem.name}
            </button>
          ))}
        </div>
        {currentAdminTab === tabs[0].index && <MemberAdder />}
        {currentAdminTab === tabs[1].index && <MemberEditor />}
      </div>
    </>
  );
};

export default AdminOutline;
