import { useState, useEffect } from "react";

import {
  getInviteStatuses,
  handleAddInvite,
  handleInviteCurrent,
  handleInviteExpiry,
} from "../../../utils/admin/adder";

const MemberAdder = () => {
  const [invite, setInvite] = useState("");
  const [permissions, setPermissions] = useState({
    member: true,
    admin: false,
  });

  const [emailError, setEmailError] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [counts, setCounts] = useState([0, 0, 0]);

  const handleEmailOnBlur = (e) => {
    const { value } = e.target;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return;
    } else {
      setEmailError(true);
    }
  };

  const handleSendInvite = async () => {
    let result = await handleAddInvite(invite, permissions.admin);
    if (result) {
      setInvite("");
      setPermissions((old) => ({ ...old, admin: false }));
      getInviteStatuses(setStatuses);
    }
  };

  const textEmailError = emailError ? "text-pink-600" : "";
  const borderEmailError = emailError ? "border-pink-500 focus:border-2" : "";

  useEffect(() => {
    getInviteStatuses(setStatuses, setCounts);
  }, []);
  return (
    <div className="w-full flex flex-wrap md:w-10/12 md:max-w-6xl justify-between rounded-xl p-2">
      <div className="w-full mb-6 flex justify-between flex-wrap">
        <div className="w-full md:w-4/12 mb-2 md:mb-0 h-36 rounded-lg shadow-md border-2 border-slate-500 border-opacity-50 flex flex-col items-center justify-center p-3">
          <div className="w-full mb-2">
            <label className={`text-xs ${textEmailError}`}>email</label>
            <input
              id="email"
              name="email"
              value={invite}
              onChange={(e) => {
                if (emailError) setEmailError(false);
                setInvite(e.target.value);
              }}
              onBlur={handleEmailOnBlur}
              className={`border rounded-lg px-2 py-1 text-sm focus:outline-none focus:shadow-md w-full ${borderEmailError}`}
            />
          </div>
          <div className="w-full flex justify-between mb-4">
            <div className="w-5/12 border flex items-center px-3">
              <input
                type="checkbox"
                disabled
                className="mr-2 checked:bg-purple-800"
                checked={permissions.member}
              />
              <span>member</span>
            </div>
            <div className="w-5/12 border flex items-center px-3">
              <input
                type="checkbox"
                id="admin"
                checked={permissions.admin}
                onChange={() =>
                  setPermissions((old) => ({ ...old, admin: !old.admin }))
                }
                className="mr-2 checked:bg-purple-800"
              />
              <span>admin</span>
            </div>
          </div>
          <button
            className="w-full bg-blue-800 text-white rounded-lg hover:bg-blue-500 disabled:bg-slate-400"
            disabled={emailError || !invite}
            onClick={() => handleSendInvite()}
          >
            send invite
          </button>
        </div>
        <div className="w-screen md:w-1/12 md:min-w-fit mb-2 md:mb-0 h-36 rounded-lg shadow-md border-2 border-slate-500 border-opacity-50">
          <div className="w-full h-full flex flex-col  items-center justify-center">
            <div className="text-lg">{statuses.length}</div>
            <div className="text-xs text-slate-300">out of</div>
            <div className="text-xl">50</div>
          </div>
        </div>
        <div className="w-full md:w-2/12 mb-2 md:mb-0 h-36 rounded-lg shadow-md border-2 border-slate-500 border-opacity-50 flex flex-col items-center justify-center text-green-600">
          <div className="text-6xl mb-5">{counts[0]}</div>
          <div className="text-sm font-bold text-opacity-50">
            invited completed
          </div>
        </div>
        <div className="w-full md:w-2/12 mb-2 md:mb-0 h-36 rounded-lg shadow-md border-2 border-slate-500 border-opacity-50 flex flex-col items-center justify-center text-pink-600">
          <div className="text-6xl mb-5">{counts[1]}</div>
          <div className="text-sm font-bold text-opacity-50">
            invites expired
          </div>
        </div>
        <div className="w-full md:w-2/12 mb-2 md:mb-0 h-36 rounded-lg shadow-md border-2 border-slate-500 border-opacity-50 flex flex-col items-center justify-center text-sky-600">
          <div className="text-6xl mb-5">{counts[2]}</div>
          <div className="text-sm font-bold text-opacity-50">
            invites pending
          </div>
        </div>
      </div>

      <div className="block max-h-[65vh] overflow-y-auto w-full">
        <table className="w-full">
          <thead className=" bg-gray-400 bg-opacity-40 sticky top-0">
            <tr>
              <td className="text-center md:text-left w-44 rounded-l-md py-0.5 md:py-2 text-[0.8rem] md:text-lg">
                name
              </td>
              <td className="text-center md:text-left w-56 py-0.5 md:py-2 text-[0.8rem] md:text-lg">
                email
              </td>
              <td className="w-48 py-0.5 md:py-2 text-center text-[0.7rem] md:text-lg">
                time reamining
              </td>
              <td className="w-[7.35rem] rounded-r-md py-0.5 md:py-2 text-center text-[0.8rem] md:text-lg">
                status
              </td>
            </tr>
          </thead>
          <tbody className="divide-y">
            {statuses.map((el, i) => (
              <tr ley={i}>
                <td className="py-1 text-[0.5rem] md:text-sm text-center md:text-left">
                  {el.name}
                </td>
                <td className="py-1 text-[0.5rem] md:text-sm text-center md:text-left">
                  {el.email}
                </td>
                <td className="py-1 text-center text-[0.5rem] md:text-sm">
                  {handleInviteExpiry(el.expiry)}
                </td>
                <td className="py-1 flex justify-center text-[0.5rem] md:text-sm">
                  {handleInviteCurrent(el.expiry, el.used)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberAdder;
