import React from "react";
import { FaCcStripe } from "react-icons/fa";
import { HISTORY_TYPES } from "../utils";

const HistoryItem = ({ type, amount, message, time }) => {
  const handleIconType = (type) => {
    switch (type) {
      case 0:
        return "n / a";
      case (1, "payment"):
        return (
          <div className="w-full h-6">
            <FaCcStripe className="h-full w-full" />
          </div>
        );
      default:
        return "n / a";
    }
  };
  return (
    <>
      <tr className=" odd:bg-slate-200">
        <td className="py-3 text-center">{handleIconType(type)}</td>
        <td className="py-3 text-center">{amount}</td>
        <td className="py-3 text-center">{message}</td>
        <td className="py-3 text-right pr-3 text-slate-500/40">{time}</td>
        {/* <td className="py-3 text-right pr-3 text-white">
          <button className="bg-slate-600/50  hover:bg-slate-600 py-1 px-2 rounded-xl">
            view
          </button>
        </td> */}
      </tr>
      {/* <div className="w-full h-auto border border-x-orange-600 rounded-lg my-2 flex py-2 px-1 items-center">
        <div className="w-10 h-7">
          <FaCcStripe className="w-full h-full" />
        </div>
        <div>history item</div>
      </div> */}
    </>
  );
};

export default HistoryItem;
