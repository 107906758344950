import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { TbLogout } from "react-icons/tb";

const items = [
  { display: "user", link: "user", active: true },
  { display: "admin", link: "admin", active: false },
];

const handleNavClick = (e) => {
  const navTabLeft = e.target.getBoundingClientRect().left;
};

const Nav = () => {
  const auth = useAuthUser();
  const signout = useSignOut();
  const navigate = useNavigate();
  const [navPos, setNavPos] = useState(0);
  const navIndicator = useRef();

  return (
    <div
      className="w-screen h-auto md:py-5 py-2 bg-slate-100 shadow-sm flex relative justify-between"
      ref={navIndicator}
    >
      <div className="md:w-auto flex">
        {items.map((item, i) => {
          if (item.link === "admin") {
            const { isAdmin } = auth();
            return (
              isAdmin && (
                <div className="md:text-xl text-sm md:px-6 mx-2 relative">
                  <Link to={item.link}>{item.display}</Link>
                </div>
              )
            );
          }
          return (
            <div className="md:text-xl text-sm md:px-6 mx-2 relative">
              <Link to={item.link}>{item.display}</Link>
            </div>
          );
        })}
      </div>
      <div
        className="px-2 text-2xl flex w-10 overflow-hidden transition-all ease-in-out hover:w-28 duration-500 cursor-pointer"
        onClick={() => {
          signout();
          navigate("/login");
        }}
      >
        <div className="flex items-center pr-2">
          <TbLogout />
        </div>
        <div className="text-sm flex items-center ">logout</div>
      </div>
    </div>
  );
};

export default Nav;
