import { TiThumbsOk } from "react-icons/ti";
import { motion } from "framer-motion";

const SignupCompleted = () => {
  return (
    <div className="w-screen min-h-screen flex flex-col justify-center items-center">
      <span className="text-green-600 text-6xl mb-4">
        <motion.div
          animate={{ rotate: [0, 15, -15, 15, -15, 15, 0] }}
          transition={{
            ease: "linear",
            duration: 1,
            repeat: Infinity,
            repeatDelay: 4,
          }}
        >
          <TiThumbsOk />
        </motion.div>
      </span>
      <h3 className="text-3xl w-10/12 md:max-w-lg text-center mb-8">
        thanks! you've successfully created your account
      </h3>
      <p className="text-sm w-9/12 md:max-w-2xl text-center text-slate-400">
        please wait for an email/text from us to log into your account and see
        your generated details
      </p>
    </div>
  );
};

export default SignupCompleted;
