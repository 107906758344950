import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/Login";
import Error from "./pages/status/error";
import Signup from "./pages/Signup";
import AdminOutline from "./pages/admin/AdminOutline";
import SignupCompleted from "./pages/status/SignupCompleted";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import UserOutline from "./pages/user/UserOutline";
import Frame from "./pages/Frame";
import Payer from "./pages/user/components/Payer";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth loginPath="/login">
        <Frame />
      </RequireAuth>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "user",
        element: <UserOutline />,
      },
      {
        path: "admin",
        element: <AdminOutline />,
      },
      {
        path: "payment",
        element: <Payer />,
      },
    ],
  },
  {
    path: "signup/:id",
    element: <Signup />,
    errorElement: <Error />,
  },
  { path: "login", element: <Login />, errorElement: <Error /> },
  {
    path: "creation-success",
    element: <SignupCompleted />,
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="font-spaceMono bg-slate-200">
      <AuthProvider
        authType="cookie"
        authName="_auth"
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https"}
      >
        <RouterProvider router={router} />
        {/* <AdminOutline /> */}
      </AuthProvider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
